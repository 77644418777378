import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useKcAttributesContext } from "@docaposte-agility/da-keycloakify";
import { useApiPath } from "../../hooks/useApiPath";

interface Notification {
  icon: string;
  title: string;
  description: string;
}

export default function GreffeNotification() {
  const [notification, setNotification] = useState<Notification | null>();

  const urlApi = useApiPath();

  const { getAttributeValue } = useKcAttributesContext();
  const authToken = getAttributeValue("greffe_api_token_auth");

  const getNotification = async () => {
    if (urlApi) {
      const dataNotifications = await axios({
        method: "get",
        url: `${urlApi}/api-greffe/notifications`,
        headers: {
          "X-Auth-Mytribunal": authToken as string,
        },
      });
      if (dataNotifications.data.length > 0) {
        setNotification(dataNotifications.data[0]);
      }
    }
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!notification) {
    return null;
  }

  return (
    <Alert
      onClose={() => setNotification(null)}
      icon={false}
      sx={{
        backgroundColor: "white",
        border: "2px solid",
        borderColor: "#EDEDF2",
        borderRadius: 1,
        color: "black",
      }}
    >
      <Stack direction="row" spacing={2}>
        <FontAwesomeIcon icon={notification.icon as IconProp} size="3x" />
        <Stack>
          <Typography fontWeight="600" sx={{ fontSize: "14px" }}>
            {notification.title}
          </Typography>
          <div
            style={{ fontSize: "12px" }}
            dangerouslySetInnerHTML={{ __html: notification.description }}
          />
        </Stack>
      </Stack>
    </Alert>
  );
}
