import { memo } from "react";
import {
  Stack,
  Box,
  Typography,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  useComponentsContext,
  useKcMessagesContext,
  useFormContext,
  LoginProps,
  onSubmit,
  useKcAttributesContext,
  useGetCustomClassName,
} from "@docaposte-agility/da-keycloakify";

import { CobaltIcon, theme } from "@docaposte-agility/da-design-system";
import GreffeNotification from "../components/custom/GreffeNotification";

const Login = memo(({ kcContext, ...props }: LoginProps) => {
  const { url, realm, auth, usernameHidden } = kcContext;
  const { msgStr } = useKcMessagesContext();
  const {
    getTextField,
    getPasswordTextField,
    getSubmitButton,
    getAlert,
    getLink,
  } = useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { advancedMsgStr } = useKcMessagesContext();

  const { getAttributeValue } = useKcAttributesContext();

  const { getClassName } = useGetCustomClassName({});

  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    trigger,
    control,
    formState: { isValid },
  } = useForm(getFormProps());

  const identityMsg = !realm?.loginWithEmailAllowed
    ? "username"
    : realm?.registrationEmailAsUsername
    ? "email"
    : "usernameOrEmail";

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-form-login"
    >
      <Stack alignItems="center" spacing={3} height="100%">
        <Stack
          spacing={2}
          className={getClassName("kcFormGroupClass")}
          alignItems="center"
          width="100%"
          minWidth={matchesSmDown ? "300px" : "350px"}
          id="kc-form-login-password-wrapper"
        >
          <Stack width="70%">
            <Typography fontSize="15px" fontWeight={600} color="#2e2e2e">
              {msgStr("email")}
            </Typography>
            {!usernameHidden && (
              <Box id="kc-form-login-id-wrapper">
                {getTextField({
                  name: "username",
                  id: "kc-form-login-id",
                  control: control,
                  rules: GetRules("email"),
                  displayname: msgStr(identityMsg),
                  placeholder: advancedMsgStr("placeholderUsernameInput"),
                  label: null,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CobaltIcon name="user" />
                      </InputAdornment>
                    ),
                  },
                })}
              </Box>
            )}
          </Stack>
          <Stack width="70%" spacing={1}>
            <Stack>
              <Typography fontSize="15px" fontWeight={600} color="#2e2e2e">
                {msgStr("password")}
              </Typography>
              {getPasswordTextField({
                name: "password",
                id: "kc-form-login-password",
                control: control,
                rules: GetRules("password"),
                placeholder: advancedMsgStr("placeholderPasswordInput"),
                label: null,
              })}
            </Stack>
            {realm?.resetPasswordAllowed && (
              <Box
                id="kc-reset-password-link-container"
                className={getClassName("kcFormOptionsWrapperClass")}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {getLink({
                  href: url.loginResetCredentialsUrl,
                  id: "kc-forgot-password-link",
                  children: advancedMsgStr("doForgotPassword"),
                })}
              </Box>
            )}
          </Stack>
          <Box
            className={getClassName("kcFormGroupClass")}
            id="kc-form-buttons"
          >
            <input
              type="hidden"
              id="id-hidden-input"
              name="credentialId"
              {...(auth?.selectedCredential !== undefined
                ? {
                    value: auth.selectedCredential,
                  }
                : {})}
            />
            {getSubmitButton({
              kcprops: props,
              message: msgStr("doLogIn"),
            })}
          </Box>
        </Stack>
        {kcContext.message?.summary !== undefined && (
          <Box mt={3} width="70%">
            {getAlert({
              message: kcContext.message,
              kcContext: kcContext,
            })}
          </Box>
        )}
        <Stack mt={2} spacing={1} width="70%" color="#3c4043">
          <Typography variant="body2">
            {getAttributeValue("greffe_password_policy")}
          </Typography>
          <Typography variant="body2">
            {getAttributeValue("greffe_brute_force_protection")}
          </Typography>
          <Typography variant="body2">
            {getAttributeValue("greffe_rules_description")}
          </Typography>
        </Stack>
        <Box width="70%">
          <GreffeNotification />
        </Box>
      </Stack>
    </form>
  );
});

export default Login;
