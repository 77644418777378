import { CustomMessages } from "./CustomMessages";
import { fr_messages as defaultFrMessages } from "@docaposte-agility/da-keycloakify";

export const fr_messages: CustomMessages = {
  ...defaultFrMessages,
  or: "ou",
  accessTo: "Accédez à",
  title: "Connexion à {0}",
  requiredFields: "Champs requis",
  requiredCorrectEmail: "Doit être un email valide",
  yourAccount: "votre compte",
  totpRequiredDigitsNumber: "Doit contenir {0} chiffres",
  loginTotpStep3DeviceName:
    "Donnez un nom à votre appareil pour vous aider à gérer vos appareils OTP",
  endOfActionMessage: "Vous pouvez fermer cette fenêtre de navigation.",
  doLogIn: "Connexion au portail",
  email: "Adresse email",

  // Changing Keycloak messages
  doRegister: "Activer son compte",
  registerTitle: "S'enregistrer sur",
  logoutOtherSessions: "Déconnecter les autres appareils",
  "identity-provider-login-label": "Ou connectez-vous avec :",
  cookieNotFoundMessage:
    "Cookie introuvable. Assurez-vous que les cookies sont activés sur votre navigateur.",
  loginTotpScanBarcode: "Scanner le QR code",

  // Translate Keycloak messages
  doTryAgain: "Essayer de nouveau",
  "error-invalid-value": "Valeur invalide",
  "error-invalid-blank": "Veuillez spécifier une valeur",
  "error-empty": "Veuillez spécifier une valeur",
  credentialSetupRequired:
    "Impossibble de se connecter, une configuration est nécessaire sur ce compte",

  successLogout: "Vous avez bien été déconnecté",
  failedLogout: "Une erreur est survenue lors de la déconnexion",

  userDeletedSuccessfully: "Utilisateur supprimé avec succés",

  logoutConfirmHeader: "Voulez-vous vous déconnecter ?",

  // WebAuthn
  "webauthn-display-name": "Clé de sécurité.",
  "webauthn-help-text": "Utilisez votre clé de sécurité pour vous connecter.",
  "webauthn-passwordless-display-name": "Clé de sécurité",
  "webauthn-passwordless-help-text":
    "Utilisez votre clé de sécurité pour vous connecter sans mot de passe.",
  "webauthn-login-title": "Connexion avec une clé de sécurité",
  "webauthn-registration-title": "Enregistrement de la clé de sécurité",
  "webauthn-available-authenticators": "Clés de sécurité disponibles",
  "webauthn-unsupported-browser-text":
    "WebAuthn n'est pas pris en charge par ce navigateur. Essayez-en une autre façon de vous connecter ou contactez votre administrateur.",
  "webauthn-doAuthenticate": "Se connecter avec une clé de sécurité",
  "webauthn-createdAt-label": "Créé le",

  // Webauthn Error
  "webauthn-error-title": "Erreur de clé de sécurité",
  "webauthn-error-registration":
    "Échec de l'enregistrement de votre clé de sécurité.<br/> {0}",
  "webauthn-error-api-get":
    "Échec de l'authentification par la clé de sécurité.<br/> {0}",
  "webauthn-error-different-user":
    "Le premier utilisateur authentifié n'est pas celui authentifié par la clé de sécurité.",
  "webauthn-error-auth-verification":
    "Le résultat d'authentification de la clé de sécurité n'est pas valide.<br/> {0}",
  "webauthn-error-register-verification":
    "Le résultat de l'enregistrement de la clé de sécurité n'est pas valide.<br/> {0}",
  "webauthn-error-user-not-found":
    "Utilisateur inconnu authentifié par la clé de sécurité.",

  // OTP SMS
  "otp-sms-authentication.mobile-number.invalid":
    "Votre numéro de téléphone n'est pas renseigné ou est incorrect, veuillez contacter votre administrateur.",
  "otp-sms-authentication.code.invalid": "Code incorrect",
  "otp-sms-authentication.code.internal-error": "Erreur interne au serveur",
  "otp-sms-authentication.code.expired-code": "Code expiré",
  "otp-sms-authentication.mobile-number.no-present":
    "Votre numéro de téléphone n'est pas renseigné ou est incorrect, veuillez contacter votre administrateur.",
  "otp-sms-authentication.text.otp-sms.title": "Code de vérification",
  "otp-sms-authentication.text.otp-sms.prompt":
    "Veuillez entrer le code reçu par SMS",
  "otp-sms-authentication.text.send-code":
    "Un code de vérification vient d'être envoyé au numéro terminant par {0}. Si ce n'est pas votre numéro de téléphone, ou si vous ne recevez pas de SMS, veuillez contacter votre administrateur.",
  "otp-sms-authentication.user.temporarily-disabled":
    "Votre compte a été temporairement bloqué suite à un trop grand nombre d'échecs d'authentification. Merci de réessayer de vous connecter ultérieurement.",
  "user.attributes.mobile_number": "Numéro de mobile",
  "requiredAction.da-otp-sms-required-action": "Saisir le code SMS",

  // Secret Question

  "secret_question.secret_question.title": "Question secrète",
  "secret_question.secret_question.title.create":
    "Création de la question secrète",
  "secret_question.secret_question.title.edit":
    "Modification de la question secrète",
  "secret_question.secret_question.label":
    "Quelle est votre question secrète ?",
  "secret_question.secret_question.setup_explanation":
    "La question secrète vous permettra de réinitialiser votre mot de passe le cas échéant.<br />La réponse que vous entrerez devra correspondre exactement à celle que vous allez fournir ici. (<strong>Renault</strong> est différent de <strong>renault</strong>)",
  "secret_question.secret_question.placeholder":
    "Marque de la première voiture ?",
  "secret_question.secret_answer.label":
    "Quelle est votre réponse à la question secrète ?",
  "secret_question.secret_answer.placeholder": "Renault",
  "secret_question.secret_answer.please-answer-question":
    "Afin de modifier votre mot de passe, veuillez répondre à votre question secrète",
  "secret_question.error.secret_question.invalid":
    "La question secrète est invalide, elle ne peut pas être vide.",
  "secret_question.error.secret_answer.invalid":
    "La réponse de la question secrète est invalide, elle ne peut pas être vide.",
  "secret_question.error.secret_answer.less_than_2":
    "La réponse doit contenir au moins 2 caractères.",
  "secret_question.error.secret_answer.bad_secret":
    "Votre réponse n''est pas valide.",
  "secret_question.error.secret_answer.too_much_attempt":
    "Vous avez tenté trop de réponses à la question secrète.",
  "secret_question.user.temporarily-disabled":
    "Votre compte a été temporairement bloqué suite à un trop grand nombre d''échecs de saisie. Merci de réessayer ultérieurement.",
  "secret_question.do_submit": "Valider",
  "requiredAction.da-secret-question-required-action":
    "Saisir la question secrète",
  "secret-question-text": "Modifier votre question et votre réponse secrètes",
  "da-secret-question-credential-provider": "Question et réponse secrètes",
  secret_question: "Question secrète",

  // accept_terms_and_conditions
  "accept_terms_and_conditions.page": "Page",
  "accept_terms_and_conditions.page.previous": "Précédente",
  "accept_terms_and_conditions.page.next": "Suivante",
  "accept_terms_and_conditions.title": "Accepter les termes et conditions",
  "accept_terms_and_conditions.accept_title":
    "Pour continuer, vous devez accepter les termes et conditions :",
  "accept_terms_and_conditions.accept_check_title":
    "En cochant cette case j'accepte les termes et conditions présentées",
  "accept_terms_and_conditions.accept_message": "Valider",
  "accept_terms_and_conditions.for": "pour",
  "accept_terms_and_conditions.user-temporarily-disabled":
    "L'utilisateur a été désactivé temporairement",
  "accept_terms_and_conditions.no_file": "Aucun fichier PDF spécifié.",
  "accept_terms_and_conditions.error_file":
    "Erreur lors du chargement de la CGU.",
  "accept_terms_and_conditions.loading_page": "Chargement de la page.",

  // DA Otp Mail Page
  "da-otp-mail.email.error":
    "Echec d'envoi du mail. Veuillez réessayer plus tard.",
  "da-otp-mail.email.subject": "[{0}] Code de connexion",
  "da-otp-mail.email.body.html":
    "<br/>Votre nouveau code de connexion : <b>{0}</b> <br/>Ce code à usage unique est valable pendant {1} minutes. <br/><br/>Votre équipe {2}<br/><br/>",
  "da-otp-mail.email.body.text":
    "Votre nouveau code de connexion : {0}. Ce code à usage unique est valable pendant {1} minutes. Votre équipe {2}.",
  "da-otp-mail.display.message":
    "Un courriel contenant votre code de connexion vous a été envoyé.",
  "da-otp-mail.display.info":
    "Ce code à usage unique est valide pendant {0} minutes.",
  "da-otp-mail.code": "code de connexion",
  "da-otp-mail.resend-button": "Renvoyer un code par courriel",
  "da-otp-mail.timer-unit": "s",

  // Login Verify Email
  loginVerifyEmailButton: "Vous venez de vérifier votre email ?",
  loginVerifyEmailButton2: "pour continuer l'authentification.",

  // Login
  placeholderUsernameInput: "Votre adresse email",
  placeholderPasswordInput: "Votre mot de passe",
};

export default fr_messages;
