import { Alert, Typography } from "@mui/material";
import { KcContext, kcContext } from "../../../kcContext";
import { useKcMessagesContext } from "@docaposte-agility/da-keycloakify";
import { KcAlertProps } from "./KcAlert.d";

export default function KcAlert(props: KcAlertProps) {
  const { children, message, titleClassName, typographyProps, ...others } =
    props;
  const { kcSanitize } = useKcMessagesContext();
  const anyKcContext = kcContext as KcContext;

  const getColorText = (type: string) => {
    switch (type) {
      case "error":
        return "#D00000";
      case "warning":
        return "#d98100";
      case "success":
        return "#4A801A";
      case "info":
        return "#385FFA";
      default:
        return "";
    }
  };

  const getColorBackground = (type: string) => {
    switch (type) {
      case "error":
        return "#FEF4F5";
      case "warning":
        return "#FFF4EC";
      case "success":
        return "#F4FAF6";
      case "info":
        return "#EFF2FF";
      default:
        return "";
    }
  };

  if (!message?.type || !message?.summary) {
    return <></>;
  }

  if (
    anyKcContext.pageId === "delete-account-confirm.ftl" &&
    message.type !== "warning"
  ) {
    return <></>;
  }

  const summary = message.summary.replaceAll(/{[0-9]+}/g, "");

  switch (anyKcContext.pageId) {
    case "info.ftl":
    case "error.ftl":
      return <></>;
    default:
      return (
        <Alert
          severity={message.type}
          elevation={2}
          {...others}
          sx={{
            width: "100%",
            backgroundColor: `${getColorBackground(message.type)}`,
            borderRadius: 1,
            boxShadow: "none",
            ".MuiAlert-icon": {
              mt: 0,
            },
          }}
        >
          {children ? (
            children
          ) : (
            <Typography
              className={titleClassName}
              ml={2}
              color={`${getColorText(message.type)}`}
              sx={{ overflow: "hidden", ...typographyProps?.sx }}
              {...typographyProps}
            >
              {kcSanitize(summary) || summary}
            </Typography>
          )}
        </Alert>
      );
  }
}
