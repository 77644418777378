import { memo } from "react";

import { GreffePortailJugesAppProps } from "./GreffePortailJugesApp.d";
import {
  ContextProvider,
  DaKcApp,
  getPage,
} from "@docaposte-agility/da-keycloakify";
import { MuiButton } from "../../components/custom/MuiButton";
import { createTheme } from "@mui/material";
import { MuiInput } from "../../components/custom/MuiInput";
import { ThemeProvider } from "@mui/material/styles";
import { MuiOutlinedInput } from "../../components/custom/MuiOutlinedInput";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import Login from "../../pages/Login";
import Template from "../../pages/Template";
import LoginResetPassword from "../../pages/LoginResetPassword";
import LoginUpdatePassword from "../../pages/LoginUpdatePassword";
import { I18n, useI18n } from "../../messages/i18n";
import { KcContext } from "../../kcContext";

const getCustomPage = (kcContext: KcContext, i18n: NonNullable<I18n>) => {
  const kcProps = { i18n };

  switch (kcContext.pageId) {
    case "login.ftl":
      return <Login {...{ kcContext, ...kcProps }} />;
    case "login-reset-password.ftl":
      return <LoginResetPassword {...{ kcContext, ...kcProps }} />;
    case "login-update-password.ftl":
      return <LoginUpdatePassword {...{ kcContext, ...kcProps }} />;
    default:
      return getPage(kcContext, i18n);
  }
};

const GreffePortailJugesApp = memo(
  ({ kcContext }: GreffePortailJugesAppProps) => {
    const i18n = useI18n({ kcContext });

    //NOTE: Locale not yet downloaded
    if (i18n === null) {
      return null;
    }

    const page = getCustomPage(kcContext, i18n);

    const theme = createTheme({
      typography: {
        fontFamily: `"open-sans", sans-serif`,
      },
      components: {
        MuiButton: {
          styleOverrides: MuiButton.styleOverrides,
        },
        MuiInputBase: {
          styleOverrides: MuiInput.styleOverrides,
        },
        MuiOutlinedInput: {
          styleOverrides: MuiOutlinedInput.styleOverrides,
        },
      },
    });

    if (page !== undefined) {
      return (
        <ContextProvider kcContext={kcContext} i18n={i18n}>
          <ThemeProvider theme={theme}>
            <Template {...{ kcContext, i18n }}>{page}</Template>
          </ThemeProvider>
        </ContextProvider>
      );
    }
    return (
      <ContextProvider kcContext={kcContext} i18n={i18n}>
        <DaKcApp {...{ kcContext, i18n }} />;
      </ContextProvider>
    );
  }
);

export default GreffePortailJugesApp;
library.add(fab, fas, far);
