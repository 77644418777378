export const MuiButton = {
  styleOverrides: {
    root: {
      borderRadius: 24,
      fontFamily: "Barlow",
    },
    contained: {
      backgroundColor: "#000080",
      fontWeight: "600",
      borderRadius: 4,
      boxShadow: "none",
      width: "fit-content",
      "&:hover": {
        backgroundColor: "#0221c4",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "#0069d9",
        borderColor: "#0062cc",
      },
    },
  },
};
