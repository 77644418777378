import { memo, useEffect, useState } from "react";
import { Stack, Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "../assets/css/login.css";
import axios from "axios";
import { useApiPath } from "../hooks/useApiPath";
import {
  useKcAttributesContext,
  useComponentsContext,
  TemplateProps,
  useGetCustomClassName,
} from "@docaposte-agility/da-keycloakify";
import ApplyComponent from "../contexts/ApplyComponents";

const Template = memo(
  ({ children, kcContext, i18n, ...props }: TemplateProps) => {
    const theme = useTheme();

    const matchesXsDown = useMediaQuery("(max-width:315px)");
    const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXlUp = useMediaQuery(theme.breakpoints.up("xl"));

    const [urlLogo, setUrlLogo] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { getClassName } = useGetCustomClassName({});

    const setters = useComponentsContext();

    useEffect(() => {
      ApplyComponent(setters);
      getLogo();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getAttributeValue } = useKcAttributesContext();
    const authToken = getAttributeValue("greffe_api_token_auth");

    const urlApi = useApiPath();

    const getLogo = async () => {
      try {
        if (urlApi) {
          const dataLogo = await axios({
            method: "get",
            url: `${urlApi}/api-greffe/logo`,
            headers: {
              "X-Auth-Mytribunal": authToken as string,
            },
          });
          setUrlLogo(dataLogo.data.logo);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100vh" }}
          id="waiting-container"
        >
          <CircularProgress id="waiting-progress" />
        </Box>
      );
    }

    if (matchesSmDown) {
      return (
        <Box
          sx={{
            backgroundColor: "#e7f0f7",
          }}
          id="mb-container"
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            id="mb-stack-container"
          >
            <Box
              sx={{
                width: "100%",
                px: matchesXsDown ? 0 : 4,
                pb: matchesXsDown ? 0 : 4,
                height: "100%",
                mt: 4,
              }}
              id="mb-paper-container"
            >
              <Paper
                elevation={1}
                sx={{
                  position: "relative",
                  p: 2,
                }}
                id="mb-paper"
              >
                <Stack
                  sx={{ m: 2 }}
                  alignItems="center"
                  id="mb-header-container"
                >
                  <img
                    src={urlLogo || require("../assets/img/Logo.png")}
                    width="250px"
                    alt="Logo tribunal de commerce"
                  />
                  <Box id="mb-form-container">{children}</Box>
                </Stack>
              </Paper>
            </Box>
          </Stack>
        </Box>
      );
    }

    return (
      <Stack
        direction="row"
        className={getClassName("kcLoginClass")}
        id="desktop-container"
        height="fit-content"
      >
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mx: 8,
            my: 2,
            width: "700px",
          }}
          flex="4"
          className={getClassName("kcFormCardClass")}
          id="kc-form-wrapper"
          spacing={4}
        >
          <img
            src={urlLogo || require("../assets/img/Logo.png")}
            style={{
              maxWidth: "450px",
              minWidth: "250px",
              maxHeight: "500px",
              minHeight: "100px",
            }}
            alt="Logo tribunal de commerce"
          />
          {children}
        </Stack>
        <Box
          flex="6"
          minHeight="100vh"
          sx={{
            backgroundImage:
              "url(" + require("../assets/img/background-greffe.jpg") + ") ",
            backgroundPosition: matchesXlUp ? "none" : "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#e7f0f7",
          }}
        />
      </Stack>
    );
  }
);

export default Template;
