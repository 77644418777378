import {
  ComponentsContextProps,
  KcAlertProps,
} from "@docaposte-agility/da-keycloakify";

import { KcAlert } from "../components";

export default function ApplyComponent(setters: ComponentsContextProps) {
  const { setCustomAlert } = setters;

  setCustomAlert(() => (props: KcAlertProps) => <KcAlert {...props} />);
}
