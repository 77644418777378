import { Box } from "@mui/material";
import { useKcMessagesContext } from "@docaposte-agility/da-keycloakify";
import { LoginInfoProps } from "./LoginInfo.d";

const LoginInfo = (props: LoginInfoProps) => {
  const { condition, node, kcContext, containerProps } = props;
  const { realm, registrationDisabled, url } = kcContext;
  const { msg } = useKcMessagesContext();

  const conditions = condition
    ? condition
    : realm?.password && realm?.registrationAllowed && !registrationDisabled;

  if (!conditions) {
    return <></>;
  }

  return (
    <Box id="kc-registration" {...containerProps}>
      {node || (
        <span>
          {msg("noAccount")}
          <a tabIndex={6} href={url.registrationUrl}>
            {msg("doRegister")}
          </a>
        </span>
      )}
    </Box>
  );
};

export default LoginInfo;
