import React from "react";
import { GreffePortailJugesApp } from "./login/components";
import { kcContext } from "./login/kcContext";
import { CssBaseline } from "@mui/material";
import { WaitingScreen } from "@docaposte-agility/da-keycloakify";
import "./index.css";
import "./login/assets/css/login.css";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    {kcContext !== undefined ? (
      <>
        <CssBaseline />
        <GreffePortailJugesApp kcContext={kcContext} />
      </>
    ) : (
      <WaitingScreen />
    )}
  </React.StrictMode>
);
