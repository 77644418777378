import React, { memo } from "react";
import {
  Stack,
  Box,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  LoginResetPasswordProps,
  onSubmit,
  useComponentsContext,
  useFormContext,
  useGetCustomClassName,
  useKcMessagesContext,
} from "@docaposte-agility/da-keycloakify";
import { CobaltIcon, theme } from "@docaposte-agility/da-design-system";

const LoginResetPassword = memo(
  ({ kcContext, ...props }: LoginResetPasswordProps) => {
    const { realm, url } = kcContext;
    const { msg, msgStr } = useKcMessagesContext();
    const { getLink, getSubmitButton, getTextField } = useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();

    const {
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    const { advancedMsgStr } = useKcMessagesContext();

    const { getClassName } = useGetCustomClassName({});

    const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    const identityMsg = !realm?.loginWithEmailAllowed
      ? "username"
      : realm?.registrationEmailAsUsername
      ? "email"
      : "usernameOrEmail";

    return (
      <Stack width="70%">
        <form
          action={url?.loginAction}
          onSubmit={(event) => onSubmit(event, isValid, trigger)}
          method="post"
          id="kc-form-login"
        >
          <Stack
            spacing={3}
            className={getClassName("kcFormGroupClass")}
            alignItems="center"
            minWidth={matchesSmDown ? "300px" : "350px"}
          >
            <Box id="kc-username-wrapper" width="100%">
              <Typography fontSize="15px" fontWeight={600} color="#2e2e2e">
                Adresse email
              </Typography>
              {getTextField({
                name: "username",
                id: "username",
                control: control,
                rules: GetRules("email"),
                displayname: msgStr(identityMsg),
                placeholder: advancedMsgStr("placeholderUsernameInput"),
                label: null,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CobaltIcon name="user" />
                    </InputAdornment>
                  ),
                },
              })}
            </Box>
            <Box className={getClassName("kcFormOptionsWrapperClass")}>
              {getLink({
                href: url?.loginUrl,
                id: "kc-back-to-login",
                children: msg("backToLogin"),
              })}
            </Box>
            <Box
              sx={{ mt: 1, width: "100%" }}
              display="flex"
              justifyContent="center"
              className={getClassName("kcFormGroupClass")}
            >
              {getSubmitButton({
                kcprops: props,
                message: msgStr("doSubmit"),
              })}
            </Box>
          </Stack>
        </form>
      </Stack>
    );
  }
);

export default LoginResetPassword;
