import { createUseI18n } from "keycloakify/login/i18n/i18n";

import en_messages from "./en_messages";
import fr_messages from "./fr_messages";
import { CustomMessageKey } from "./CustomMessages";

export const { useI18n } = createUseI18n<CustomMessageKey>({
  en: en_messages,
  fr: fr_messages,
});

export type I18n = ReturnType<typeof useI18n>;
