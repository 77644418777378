import {
  DaKcContextType,
  KcCustomPage,
} from "@docaposte-agility/da-keycloakify";
import { createGetKcContext } from "keycloakify/login";

export const ExtraPages: Array<KcCustomPage> = [
  { pageId: "login-reset-password.ftl" },
  { pageId: "login-update-password.ftl" },
];

export const { getKcContext } = createGetKcContext<DaKcContextType>();

export const { kcContext } = getKcContext() || {};

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
