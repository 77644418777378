import React, { memo } from "react";
import { Stack, Box, Typography, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  useComponentsContext,
  useKcMessagesContext,
  useFormContext,
  onSubmit,
  LoginUpdatePasswordProps,
  useKcAttributesContext,
  useGetCustomClassName,
} from "@docaposte-agility/da-keycloakify";
import { theme } from "@docaposte-agility/da-design-system";

const LoginUpdatePassword = memo(
  ({ kcContext, ...props }: LoginUpdatePasswordProps) => {
    const { messagesPerField, url, isAppInitiatedAction } = kcContext;
    const { msg, msgStr } = useKcMessagesContext();
    const {
      getAlert,
      getSubmitButton,
      getPasswordTextField,
      getSecondaryButton,
      getCheckbox,
    } = useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();

    const { register, trigger, control, formState } = useForm(getFormProps());

    const { isValid } = formState;

    const { getAttributeValue } = useKcAttributesContext();

    const { getClassName } = useGetCustomClassName({});

    if (messagesPerField.exists("password-new")) {
    }

    const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) =>
          onSubmit(event, isValid, trigger, isAppInitiatedAction)
        }
        method="post"
        id="kc-form-login"
      >
        <Stack
          spacing={3}
          className={getClassName("kcFormClass")}
          alignItems="center"
          minWidth={matchesSmDown ? "300px" : "350px"}
        >
          <Box id="kc-password-new-wrapper" width="70%">
            <Typography fontSize="15px" fontWeight={600} color="#2e2e2e">
              {msg("passwordNew")}
            </Typography>
            {getPasswordTextField({
              name: "password-new",
              id: "kc-form-update-password-new",
              control: control,
              rules: GetRules("password"),
              placeholder: msgStr("passwordNew"),
              label: null,
            })}
          </Box>
          <Box id="kc-password-confirm-wrapper" width="70%">
            <Typography fontSize="15px" fontWeight={600} color="#2e2e2e">
              {msg("passwordNewConfirm")}
            </Typography>
            {getPasswordTextField({
              name: "password-confirm",
              id: "kc-form-update-password-confirm",
              control: control,
              rules: GetRules("password"),
              placeholder: msgStr("passwordNewConfirm"),
              label: null,
            })}
          </Box>
          <Stack
            display="flex"
            direction="row"
            alignItems="center"
            id="kc-login-switch-container"
          >
            {getCheckbox({
              ...register("logout-sessions"),
              defaultChecked: true,
            })}
            <Typography variant="body2" color="black">
              {msg("logoutOtherSessions")}
            </Typography>
          </Stack>
          <Stack
            sx={{ mt: 1, width: "70%" }}
            className={getClassName("kcFormGroupClass")}
            id="kc-form-buttons"
          >
            <Box
              display="flex"
              justifyContent="center"
              id="kc-login-submit-button-container"
            >
              {getSubmitButton({
                kcprops: props,
                message: msgStr("doSubmit"),
              })}
            </Box>
            {kcContext.message?.summary !== undefined && (
              <Box mt={3} width="100%">
                {getAlert({ message: kcContext.message, kcContext: kcContext })}
              </Box>
            )}
            <Stack mt={2} spacing={1} color="#3c4043">
              <Typography variant="body2">
                {getAttributeValue("greffe_password_policy")}
              </Typography>
              <Typography variant="body2">
                {getAttributeValue("greffe_rules_description")}
              </Typography>
            </Stack>
            {isAppInitiatedAction && (
              <Box
                display="flex"
                justifyContent="center"
                mt={1}
                id="kc-login-secondary-button-container"
              >
                {getSecondaryButton({
                  kcprops: props,
                  name: "cancel-aia",
                  variant: "contained",
                  value: "true",
                  type: "submit",
                })}
              </Box>
            )}
          </Stack>
        </Stack>
      </form>
    );
  }
);

export default LoginUpdatePassword;
