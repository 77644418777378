export const MuiInput = {
  styleOverrides: {
    root: {
      height: "40px",
      borderColor: "#e4e6ef",
      width: "100%",
    },
    input: {
      borderColor: "#e4e6ef",
    },
  },
};
